import React from 'react';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const FiltersDesktop = ({ currentTag, categories }) => {
  return (
    <ul className="taglist filters-desktop">
      <li><a href='#blog-listing' className='filter-skip-to'>Skip to listing</a></li>
      <li key='all'>
        <Link
          className={`hover-underline ${currentTag ? null : 'active'}`}
          to='/blog'>
          All
        </Link>
      </li>
      {categories.map(({ node: cat }) => (
        <li key={cat.frontmatter.tag}>
          <Link
            to={`/blog/${kebabCase(cat.frontmatter.tag)}/`}
            className={`hover-underline ${currentTag === cat.frontmatter.tag ? 'active' : null}`}>
            {cat.frontmatter.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

FiltersDesktop.propTypes = {
  currentTag: PropTypes.string,
  categories: PropTypes.array
};

export default FiltersDesktop;
