import React from 'react';
import { kebabCase } from 'lodash';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

const FiltersMobile = ({ currentTag, categories }) => {
  return (
    <div className="filters-mobile">
      <label htmlFor="blog-categories">Filters:</label>
      <div className="select-wrapper">
        <select
          id="blog-categories"
          value={currentTag}
          onChange={event => {
            event.preventDefault();
            navigate(
              `/blog/${kebabCase(event.target.selectedOptions[0].value)}/`
            );
          }}>
          <option value={''}>All</option>
          {categories &&
            categories.map(({ node: tag }) => (
              <option
                value={tag.frontmatter.tag.toLowerCase()}
                key={tag.frontmatter.tag}>
                {tag.frontmatter.title}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

FiltersMobile.propTypes = {
  currentTag: PropTypes.string,
  categories: PropTypes.array
};

export default FiltersMobile;
