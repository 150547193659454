import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import '../styles/prismjs.css';
import { BlogPageTemplate } from './blog-page';
import { kebabCase } from 'lodash';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

class BlogPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;

    if (!data) {
      return null;
    }

    const { edges: posts } = data.allMarkdownRemark;
    const category = data.category;
    const currentTag = pageContext.tag;
    const siteTitle = data.site.siteMetadata.title;
    const title = category ? `Blog: ${category.frontmatter.title}` : 'Blog';
    const nextPagePath = pageContext.nextPagePath;

    return (
      <Layout pageType="PageWithFilters">
        <GatsbySeo
          title={title}
          titleTemplate={`%s | ${siteTitle}`}
          description={category ? category.frontmatter.description : null}
          canonical={location.href}
          openGraph={{
            title: `${title}`,
            url: `${location.href}`,
          }}
        />
        <BlogPageTemplate
          posts={posts}
          categoryName={category ? category.frontmatter.title : null}
          currentTag={currentTag}
          relatedTags={category ? category.frontmatter.related : null}
          nextPagePath={nextPagePath}
        />
        {(category || currentTag) && (
          <div className="main blog-page">
            <section className="category-section">
              <Link
                className="button-more button-read-more button-long"
                to={`/tag/${kebabCase(currentTag)}/`}
                title={`See all blog posts, events and open-source projects tagged with: ${category.frontmatter.title}`}
              >
                {`See all blog posts, events and open-source projects tagged with: ${category.frontmatter.title}`}
              </Link>
            </section>
          </div>
        )}
      </Layout>
    );
  }
}

export default BlogPage;

export const pageQuery = graphql`
  query BlogPageByTagTemplate($tag: String, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, tags: { in: [$tag] } }
      }
      limit: $limit
    ) {
      edges {
        node {
          ...BlogPostCardFragment
        }
      }
    }
    category: markdownRemark(
      frontmatter: { templateKey: { eq: "category" }, tag: { eq: $tag } }
    ) {
      frontmatter {
        tag
        title
        related
        description
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
