import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import FiltersDesktop from './CategoriesFilter/FiltersDesktop';
import FiltersMobile from './CategoriesFilter/FiltersMobile';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const CategoriesFilter = ({ data, currentTag }) => {
  const breakpoints = useBreakpoint();
  const { edges: categories } = data.allMarkdownRemark;

  return (
    <div className="filters-wrapper">
      {breakpoints.phoneAndTabletExtended ? (
        <FiltersMobile currentTag={currentTag} categories={categories} />
      ) : null}
      {breakpoints.tabletUp ? (
        <FiltersDesktop currentTag={currentTag} categories={categories} />
      ) : null}
    </div>
  );
};

CategoriesFilter.propTypes = {
  currentCategory: PropTypes.object,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default props => (
  <StaticQuery
    query={graphql`
      query CategoriesFilterQuery {
        allMarkdownRemark(
          sort: { fields: [frontmatter___title], order: ASC }
          filter: { 
            frontmatter: { templateKey: { eq: "category" }, 
            current: { eq: true }
            }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                tag
                related
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <CategoriesFilter
        data={data}
        count={count}
        currentTag={props.currentTag}
      />
    )}
  />
);
