import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { BlogJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import { get } from 'lodash';
import Layout from '../components/Layout';
import ItemsList from '../components/ItemsList';
import CategoriesFilter from '../components/CategoriesFilter';
import TagsBox from '../components/TagsBox';
import useSiteMetadata from '../components/SiteMetadata';

export const BlogPageTemplate = ({
  posts,
  categoryName,
  categoryDescription,
  currentTag,
  relatedTags,
  nextPagePath,
}) => {
  return (
    <div className="main blog-page">
      <section className="hero grid-container flex-start">
        <div className="hero-text off-sm-1 col-sm-10 off-md-0">
          <h1 className="page-title">
            {categoryName ? categoryName : 'Blog'}
          </h1>
          <h2>{categoryDescription}</h2>
        </div>
      </section>
      <div className="grid-container flex-start relative">
        <section className="filters-section off-sm-1 col-sm-10 off-md-0 col-md-3">
          <div className="container">
            <h2 className="sr-only">Post category filter</h2>
            <CategoriesFilter currentTag={currentTag} />
          </div>
        </section>
        <section className="cards-section off-sm-1 col-sm-10 off-md-0 col-md-9">
          <div className="container">
            <div className="content">
              {posts && posts.length > 0 ? (
                <>
                  <h2 className="sr-only" id="blog-listing">
                    Showing {posts.length} {posts.length > 1 ? 'posts' : 'post'}
                  </h2>
                  <ItemsList initialItems={posts} secondPage={nextPagePath} />
                </>
              ) : (
                <div className="no-results-section">
                  <h2>Oops!</h2>
                  <p>Looks like there are no posts here... ¯\_(ツ)_/¯</p>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <TagsBox title={'Related tags'} tags={relatedTags} />
    </div>
  );
};

BlogPageTemplate.propTypes = {
  posts: PropTypes.array.isRequired,
  categoryName: PropTypes.string,
  currentTag: PropTypes.string,
  relatedTags: PropTypes.array,
  nextPagePath: PropTypes.string,
};

const BlogPage = ({ data, pageContext, location }) => {
  const { edges: latestPosts } = data.allMarkdownRemark;
  const { title, description } = useSiteMetadata();
  const lastestPostsHeadlines = latestPosts.map(post => ({
    headline: get(post, 'node.frontmatter.title'),
  }));
  const { nextPagePath } = pageContext;

  return (
    <Layout pageType="PageWithFilters">
      <GatsbySeo
        title={`Blog | ${title}`}
        description={description}
        canonical={location.href}
        openGraph={{
          title: `Blog | ${title}`,
          description: `${description}`,
          url: `${location.href}`,
        }}
      />
      <BlogJsonLd
        headline={title}
        posts={lastestPostsHeadlines}
        authorName="Cognifide"
        authorType="Organization"
        description={description}
      />
      <BlogPageTemplate posts={latestPosts} nextPagePath={nextPagePath} />
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  pageContext: PropTypes.shape({
    nextPagePath: PropTypes.string,
  }),
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPageTemplate($limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
    ) {
      edges {
        node {
          ...BlogPostCardFragment
        }
      }
    }
  }
`;
